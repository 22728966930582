
import { Component, Vue, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* STORE */
import store from '@/store';
import { auth, countries } from '@/store/namespaces';
import CountryMethods from '@/store/countries/methods/countries.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
/* INTERFACES */
import { Country } from '@/modules/countries/interfaces/countries.interface';
import { User } from '../../interfaces/user.interface';
/* LIBRARIES */
import {getCountry} from 'country-list-spanish';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
/* COMPONENTS */

@Component({
    components: {
    },
    mixins: [validationMixin],
    validations: {
    },
})
export default class FirstStep extends Vue {
    loading = true
    countries : Country[] = []
    selectedCountry : Partial<Country> = {};
    europeCountriesModal = false;


    async mounted() {
        if (await this.fetchResidenceCountries()){
            this.countries = this.residenceCountries;
            this.countries.map(el => el.name_country = getCountry(el.country_iso_code))
            if (this.userData.id_resid_country){
                this.selectedCountry.id_country = this.userData.id_resid_country.toString();
                this.selectedCountry.country_iso_code = this.userData.name_resid_country
            }
            this.loading = false
        }
    }

    /* COMPUTED */
    get countryOrdered(){
        this.countries.sort((a,b) => (a.name_country > b.name_country) ? 1 : ((b.name_country > a.name_country) ? -1 : 0))
        return this.countries
    }

    /* FUNCTIONS */
    selectCountry(country: Country) {
        this.selectedCountry = country;
    }

    submit() {
        if (this.selectedCountry.id_country && this.selectedCountry.id_country !== '-1') {
            store.state.auth.userSignUp.name_resid_country = this.selectedCountry.country_iso_code
            store.state.auth.userSignUp.id_resid_country = this.selectedCountry.id_country;
            // if (this.$route.query.cod_ref) this.$router.push({name: 'LevelCero', query:{cod_ref: this.$route.query.cod_ref }}).catch();
            // else this.$router.push({name: 'LevelCero'}).catch();
            this.$emit('nextStep')  
        } else {
            showToast('Seleccione su país de residencia','error')
        }
    }

    /* END FUNCTIONS */

    /* MODAL */
    changeEuropeCountriesModalStatus(newVal: boolean) {
        this.europeCountriesModal = newVal;
    }
    /* END MODAL */

    /* COUNTRIES */
    @countries.Action(CountryMethods.actions.FETCH_ALL_RESIDENCE_COUNTRIES)
    fetchResidenceCountries!: () => Promise<boolean>
    @countries.Getter(CountryMethods.getters.GET_ALL_RESIDENCE_COUNTRIES)
    residenceCountries!: Country[]
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_REGISTER_USER_DATA)
    userData!: Partial<User>;
}
