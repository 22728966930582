
import { Component, Vue } from 'vue-property-decorator';
/* STORE */
import store from '@/store';
import { auth, countries } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
/* INTERFACES */
import { Country } from '@/modules/countries/interfaces/countries.interface';
import { User } from '../../interfaces/user.interface';
/* COMPONENTS */
import InfoSide from '@/components/InfoSide.vue';
import Loader from '@/components/utilities/Loader.vue';
import CodeInput from 'vue-verification-code-input';
import Stepper from '@/components/utilities/Stepper.vue';
import FirstStep from '@/modules/auth/components/register/FirstStep.vue'
import SecondStep from '@/modules/auth/components/register/SecondStep.vue'
import ThirdStep from '@/modules/auth/components/register/ThirdStep.vue'
/* CONSTANTS */
import { USER_EMPTY_STATE } from '../../emptyStates/user.emptyState';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
@Component({
    components: {
        InfoSide,
        Loader,
        CodeInput,
        FirstStep,
        SecondStep,
        ThirdStep,
        Stepper
    },
})
export default class Home extends Vue {
    registerButton = {
        name: 'Iniciar sesión',
        text: '¿Ya tienes una cuenta?',
        level: 2,
        redirect: '/inicio-sesion',
    };
    $refs : any ={}
    loader: any;
    loading= false;
    $loading: any;
    steps= [
        { component: 'FirstStep', label: 'Residencia' },
        { component: 'SecondStep', label: 'Datos personales' },
        { component: 'ThirdStep', label: 'Verificación' },
    ];
    currentComponent = this.steps[0].component;
    currentStep = 1;


    created(){
        this.$socket.client.connect();
    }

    async mounted(){
        this.loading = true
        if (this.$route.query.cod_ref && !await this.codeReferrerExists(this.$route.query.cod_ref)){
            this.loading = false
            this.$router.push({name: 'NotFound'}).catch();
        }
        else{
            this.loading = false
            // this.$router.push({name: 'ErrorPage'}).catch();
        }
    }

    nextStep(){
            this.$refs.step.moveStep(this.currentStep+1);
            this.currentStep += 1;
            this.currentComponent= this.steps[this.currentStep-1].component;
    }

    prevStep(){
        if (this.currentStep !== 1){
            this.$refs.step.moveStep(this.currentStep-1);
            this.currentStep -= 1;
            this.currentComponent= this.steps[this.currentStep-1].component;
        }
        else this.$router.push({name: 'Login'}).catch();
    }

    validateStep(){
        this.$refs.component.submit()
    }

    @auth.Action(AuthMethods.actions.CODE_REFERRER_EXISTS)
    codeReferrerExists!:(cust_cr_cod_pub: any) => Promise<boolean>
    @auth.Getter(AuthMethods.getters.GET_REGISTER_USER_DATA)
    userData!: Partial<User>;

}
