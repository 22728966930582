
import { Component, Vue, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* STORE */
import { auth, users } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import UsersMethods from '@/store/users/methods/users.methods';

/* INTERFACES */
import { User } from '../../interfaces/user.interface';
import { showToast } from '@/utils/global-functions';
/* LIBRARIES */
/* FUNCTIONS */
/* COMPONENTS */
import CodeInput from 'vue-verification-code-input';
import Timer from '@/components/utilities/Timer.vue';

@Component({
    components: {
        CodeInput,
        Timer
    },
    mixins: [validationMixin],
    validations: {
    },
})
export default class ThirdStep extends Vue {

    loading = false
    email = '';
    user: Partial<User> = {};
    inputDisabled = false;
    expiredCode = false;
    invalidCode = false;
    validCode = null;
    emailSended = false;
    currentCode;

    async created() {
        this.user = this.userData;
        this.user.slug = null;
        this.email = this.user.email_user// this.user.email_user
        // this.$socket.client.emit('new_connection', this.user.email_user) 
        // this.$socket.client.on('verif_code_response',this.isCodeValid)
        await this.sendEmailRequest()
    }

    /* COMPUTED */

    /* END COMPUTED */

    /* FUNCTIONS */

    showExpiredText(){
        this.expiredCode = true;
    }

    isCodeValid(response: {msg: string, ident_user: string}){
        if (response.msg === 'Invalid user') showToast('Usuario inválido','error')
        if (response.msg ==='Valid code'){
            if (response.ident_user === this.user.email_user){this.inputDisabled = true;this.validCode = true;}
        }
        else if (response.msg ==='Expired code'){
            if (response.ident_user === this.user.email_user){this.expiredCode = true;this.invalidCode = false;this.validCode = false}
        }
        else if (response.msg ==='Invalid code'){
            if (response.ident_user === this.user.email_user){this.invalidCode = true;this.expiredCode = false;this.validCode = false}
        }
        else if (response.msg ==='Time started'){
            if (response.ident_user === this.user.email_user){this.emailSended = true;}
        }
    }

    onChange(v?: any) {
       this.currentCode = v
       this.expiredCode = false;
       this.invalidCode = false;
       if (v && v.length < 6) this.validCode = null
    }
    
    async onComplete(code: any) {
        this.expiredCode = false;
        this.invalidCode = false;
        this.userData.emailCode = code;
        // if (this.$socket.disconnected) this.$socket.client.emit('new_connection',this.user.email_user)
        // this.$socket.client.emit('verif_code', {
        //     ident_user: this.user.email_user,
        //     code: code,
        //     email_user: this.user.email_user
        // })
        if (!this.emailSended) return
        this.loading = true;
        let response = await this.validateCode({ident_user: this.user.email_user, code: code})
        if (!response) showToast('Ha ocurrido un error al verificar el código','error')
        this.isCodeValid(response)
        await this.submit();
        this.loading = false;
    }

    goBack(){
        this.$emit('prevStep')
    }

    async sendEmailRequest(){
        this.onChange()
        this.emailSended = false;
        this.loading = true;
        let response = await this.sendEmail({email_user: this.user.email_user, id_resid_country: this.user.id_resid_country, type: 'register',first_name: this.user.first_name,last_name: this.user.last_name});
        // let response = await this.sendEmail({email_user: this.user.email_user, id_resid_country: 2, type: 'register',first_name: 'Stephanie',last_name: 'Cruz'});
        if (response.error)
            showToast(response.msg,'error')
        else{
            showToast('Correo enviado satisfactoriamente', 'success')
            this.emailSended = true;
        }
        this.loading = false
        this.expiredCode = false;
    }

    async submit()
    {
        this.user.main_phone = this.user.main_phone.replace(/\s/g, '');
        if (this.validCode){
            this.loading = true;
            let registerResponse : {errorMessage: string} | boolean = await this.createClient(this.user);
                if (registerResponse === true) {
                    // if (await this.verifClient(this.user.email_user)) {
                    this.$router.push({name: 'RegisterSuccess'})
                    // }
                    // else {
                    //     this.resetValues()
                    //     showToast('Ha ocurrido un problema verificando la cuenta','error')
                    // }
                } else if (typeof registerResponse !== 'boolean') {
                    showToast(registerResponse.errorMessage,'error')
                    this.resetValues()
                }
            this.loading = false
        }         
        else 
            showToast('Código incorrecto o vencido. Vuelve a intentar', 'error')
    }

    resetValues(){
        this.inputDisabled = false;
        this.expiredCode = false;
        this.invalidCode = false;
        this.validCode = null;
        this.emailSended = false;
    }

    /* END FUNCTIONS */

    /* MODAL */

    /* END MODAL */
    @auth.Action(AuthMethods.actions.VERIF_CLIENT)
    verifClient!: (email: string) => Promise<boolean>;
    @auth.Getter(AuthMethods.getters.GET_REGISTER_USER_DATA)
    userData!: User;
    @auth.Getter(AuthMethods.getters.GET_COUNTRY_PHONE_CODE)
    countryCode!: string;
    @auth.Action(AuthMethods.actions.CREATE_CLIENT)
    createClient!: (user: Partial<User>) => Promise<{errorMessage: string} | boolean>;
    @auth.Action(AuthMethods.actions.SEND_EMAIL)
    sendEmail!: (data:{email_user: string,id_resid_country: number | string, type: string,first_name:string,last_name:string}) => Promise<{error: boolean, msg: string}>
    @users.Action(UsersMethods.actions.VALIDATE_CODE)
    validateCode!: (data: {ident_user: string, code: string}) => Promise<{msg: string, ident_user: string}>
}
