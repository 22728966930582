
import { Component, Vue, Watch } from 'vue-property-decorator';
import VuePhoneNumberInput from 'vue-phone-number-input';
/* VUELIDATE */
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* STORE */
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { User } from '../../interfaces/user.interface';
import store from '@/store';
/* INTERFACES */
/* LIBRARIES */
/* FUNCTIONS */
import { getYMD } from '@/utils/date-functions';
import { showToast, splitFullName, splitFullLastName} from '@/utils/global-functions';
/* CONSTANTS */
import { USER_EMPTY_STATE } from '@/modules/auth/emptyStates/user.emptyState';
import { formatDate } from '@/utils/date-functions';
/* COMPONENTS */
import VueRecaptcha from 'vue-recaptcha';
import TermsPopup from '@/modules/auth/components/TermsPopup.vue';
import TermsEuropeContent from '@/modules/auth/components/termsContent/TermsEuropeContent.vue';
import TermsContent from '@/modules/auth/components/termsContent/TermsContent.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Toast from '@/components/utilities/Toast.vue';

@Component({
    components: {
        TermsEuropeContent,
        TermsContent,
        VueRecaptcha,
        TermsPopup,
        SvgIcon,
        Toast,
        VuePhoneNumberInput
    },
    mixins: [validationMixin],
    validations: {
        fullName: { required },
        fullLastName: { required },
        user: {
            email_user: { required, email },
            // date_birth:{required,validFormatDate, validYearDate, validAdultYearDate},
            password: {
                required,
                minLength: minLength(8),
                containsUppercase: function (value) {
                    return /[A-Z]/.test(value);
                },
                containsNumber: function (value) {
                    return /[0-9]/.test(value);
                },
            },
            main_phone: {required},
            main_phone_code: {required},
            main_phone_full: {required},
            // gender: { required },
            // main_phone: { required },
            // main_phone_wha: { required },
            // name_nationality_country: { required },
            ok_legal_terms: { accepted: (val) => val === true },
            // pol_exp_per: { accepted: (val) => val === true },
        },
        repeatPassword: { required, sameAs: function (value){
                //@ts-ignore
                return value === this.user.password
        }},
    },
})
export default class SecondStep extends Vue {
    $refs : any ={}
    loading = false
    recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
    termsModal = false;
    captchaRendered = false;
    user: Partial<User> = {};
    fullName = '';
    fullLastName = '';
    repeatPassword: string = '';
    showPassword = false;
    showRepeatPassword = false;
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    dropdownCountries: any = [];

    async mounted() {
        this.loading = true;
        try{
            this.setUserData(USER_EMPTY_STATE);
            // if (!await this.fetchAllNationalityCountries()) throw new Error()
            // this.spanishTranslateCountries = getSpanishLanCountries(this.nationalityCountries)
            // this.dropdownCountries.push(this.userData.name_resid_country);
            this.user = this.userData;
            this.user.captcha = undefined;
            this.alreadyFull();
            let residCountry = this.user.id_resid_country
            this.dropdownCountries.push(store.state.auth.userSignUp.name_resid_country);
        // if (typeof residCountry === 'string') residCountry = parseInt(residCountry);
        //     // this.politicallyExponseQuestion = await this.politicallyExponse(residCountry);
        //     if (this.$route.query.cod_ref && typeof this.$route.query.cod_ref === 'string') 
        //         this.user.referral_node = this.$route.query.cod_ref;
            this.loading = false
        }catch(e){
            this.loading = false
            this.$router.push({name: 'ErrorPage'}).catch(error => {})
        }
    }

    /* COMPUTED */

    get isEuropeVersion() {
        return process.env.VUE_APP_EUROPE_VERSION
    }

    get mainPhoneCountryCode(){

        return store.state.auth.userSignUp.name_resid_country || 'CO'
    }

    /* END COMPUTED */

    /* FUNCTIONS */

    async submit() {
        this.$v.$touch();
        if (!this.$v.$invalid && this.user.captcha) {
            this.loading = true;
            this.user.date_legacy_reg = getYMD(new Date());
            let userExistsResponse = await this.verifyIdentUserExistence({email_user: this.user.email_user, phone_number: this.user.main_phone_full})
            if (!userExistsResponse.error){
                this.loading = false
                this.setUserData(this.user);
                // if (this.$route.query.cod_ref) this.$router.push({name: 'LevelCeroVerification', query:{cod_ref: this.$route.query.cod_ref}}).catch();
                // else this.$router.push({name: 'LevelCeroVerification'}).catch();
                this.$emit('nextStep')
            }else {
                this.loading = false
                showToast(userExistsResponse.msg,'error')
            }
            this.$refs.recaptcha.reset();
        }
        else if (!this.user.captcha)
            showToast('Confirma que no eres un robot','error')
        this.$refs.recaptcha.reset();
    }

    goBack(){
        this.$emit('prevStep')
    }

    expiredMethod(){
        this.$refs.recaptcha.reset();
    }

    renderMethod(){
        this.captchaRendered = true;
    }

    verifyMethod(id: string){
        this.user.captcha = id;
    }

    errorMethod(el: any){
     //   showToast('Error verificando el reCaptcha','error')
     //   this.$refs.recaptcha.reset();
    }

    alreadyFull(){
        if (this.userData.email_user){
            this.fullName = this.userData.first_name+' '+this.userData.second_name;
            this.fullLastName = this.userData.last_name+' '+this.userData.second_last_name;
            this.repeatPassword = this.userData.password;
            // if (this.userData.gender)
            //     this.currentGender = this.genders.find(el =>{ if(el.key === this.userData.gender) return el}).name;
            // if (this.userData.name_nationality_country)
            // this.currentNacionalityCountry = this.nationalityCountries.find(el=>{ if (el.name_country === this.userData.name_nationality_country) return el}).spanish_name;
        }
    }

    updateMainPhone(payload: any) {
        if (payload.nationalNumber) {
            let formatNational = payload.nationalNumber;
            if (formatNational && formatNational.charAt(0) === '0') {
                formatNational =formatNational.substring(1);
            } 
            this.$v.user.main_phone_full.$model = '+'+ payload.countryCallingCode + formatNational;
            this.$v.user.main_phone.$model = formatNational;
            this.$v.user.main_phone_code.$model = '+' + payload.countryCallingCode;
        }
    }

    /* END FUNCTIONS */

    /* MODAL */
    changeTermsModalStatus(newVal: boolean) {
        this.termsModal = newVal;
    }
    
    /* END MODAL */

    /* WATCHERS */
    @Watch('fullName')
    getFirstAndSecondName(newVal: string) {
        let names = splitFullName(newVal);
        this.user.first_name = names.firstName;
        this.user.second_name = names.secondName;
    }
    @Watch('fullLastName')
    getFirstAndSecondLastName(newVal: string) {
        let names = splitFullLastName(newVal);
        this.user.last_name = names.firstLastName;
        this.user.second_last_name = names.secondLastName;
    }


    /* END WATCHERS */

    @auth.Getter(AuthMethods.getters.GET_REGISTER_USER_DATA)
    userData!: Partial<User>;
    @auth.Action(AuthMethods.actions.SAVE_USER_SIGNUP)
    setUserData!: (user: Partial<User>) => void;
    @auth.Action(AuthMethods.actions.VERIFY_IDENT_USER_EXISTENCE)
    verifyIdentUserExistence!: (data:{email_user:string, phone_number?:string}) => Promise<{msg?: string, error: boolean}>
}
